import { SVGProps } from "react";

export function ChatIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CircleChatIcon({
  width = 35,
  height = 36,
  viewBox = "0 0 35 36",
  stroke = "#008934",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M17.5 32.5832C25.5542 32.5832 32.0834 26.054 32.0834 17.9998C32.0834 9.94568 25.5542 3.4165 17.5 3.4165C9.44587 3.4165 2.91669 9.94568 2.91669 17.9998C2.91669 26.054 9.44587 32.5832 17.5 32.5832Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.417 20.75C24.417 21.1478 24.259 21.5294 23.9777 21.8107C23.6963 22.092 23.3148 22.25 22.917 22.25H13.917L10.917 25.25V13.25C10.917 12.8522 11.075 12.4706 11.3563 12.1893C11.6376 11.908 12.0192 11.75 12.417 11.75H22.917C23.3148 11.75 23.6963 11.908 23.9777 12.1893C24.259 12.4706 24.417 12.8522 24.417 13.25V20.75Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
