import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import useUser from "hooks/useUser";
import { useLoading } from "providers/loading";
import { apiRegisterWithGg } from "services/api/user";
import { useLanguageContext } from "./LanguageProvider";

const GoogleOneTap = () => {
  const { isLoggedIn } = useUser();
  const { setLoading } = useLoading();
  const { language } = useLanguageContext();
  const redirect_uri = `${window.location.protocol}//${window.location.host}`;

  const onConfirm = async (data: CredentialResponse) => {
    const { credential } = data || {};
    console.log(data);
    setLoading(true);

    try {
      const registerData = await apiRegisterWithGg({
        code: credential,
        language,
        redirect_uri,
        check_signup: true,
      });
      console.log(registerData);

      //   if (res?.data?.is_signed_up) {
      //     onGetUserInformation()
      //       .then((err) => {
      //         onDirectNextPageOfLogin();
      //       })
      //       .catch((err) => {
      //         console.log(false);
      //       });
      //   } else {
      //     const searchParams = new URLSearchParams({
      //       [SEARCH_PARAMS.uid]: code,
      //       [SEARCH_PARAMS.mail]: res.data.user,
      //       [SEARCH_PARAMS.username]: res.data.full_name,
      //     }).toString();
      //     directKeepSearch(PATHS.register, searchParams);
      //   }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  if (isLoggedIn) return null;

  return (
    <GoogleLogin
      onSuccess={onConfirm}
      onError={() => {
        console.log("Login Failed");
      }}
      useOneTap
    />
  );
};

export default GoogleOneTap;
